<template>
  <b-card no-body>
    <b-modal
      ref="modal-form"
      v-model="showModalForm"
      centered
      :title="$t('apps.setting.actions.createModuleApproval')"
      size="md"
      @hidden="resetForm"
    >
      <validation-observer ref="formRef">
        <b-form @submit.stop.passive="handleSubmit">
          <b-form-group
            :label="$t('globalSingular.name')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                :state="errors.length > 0 ? false:null"
                placeholder="PO Approval"
                :disabled="actions.isPreview"
              />
              <small class="text-danger">{{ errors[0]}}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('globalSingular.transactionType')">
            <validation-provider
              #default="{ errors }"
              name="txType"
            >
              <v-select
                id="txType"
                v-model="form.transactionTypeCode"
                :options="LOV.transactions"
                :reduce="field => field.code"
                label="remark"
                :state="errors.length > 0 ? false:null"
                :disabled="actions.isPreview"
              />
              <small class="text-danger">{{ errors[0]}}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('globalSingular.type')">
            <validation-provider
              #default="{ errors }"
              name="type"
            >
              <v-select
                id="type"
                v-model="form.type"
                :options="LOV.types"
                :state="errors.length > 0 ? false:null"
                :disabled="actions.isPreview"
              />
              <small class="text-danger">{{ errors[0]}}</small>
            </validation-provider>
          </b-form-group>

          <div v-if="form.type === 'LEVELING'">
            <h5 class="font-weight-bolder">Approvers</h5>
            <b-row class="mb-2" v-for="(approver, index) in form.approvers" :key="approver.no">
              <b-col md="2" class="text-center">
                <b-form-group
                  :label="$t('globalSingular.no')"
                >
                  {{ approver.no }}
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('globalSingular.approvers')"
                  label-for="approvers"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="approvers"
                  >
                    <v-select
                      id="approvers"
                      v-model="form.approvers[index].userApprover"
                      :options="LOV.users"
                      :reduce="field => field.user.id"
                      label="remark"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    >
                      <template v-slot:option="option">
                        {{ option.user.user_name }} - {{ option.user.user_email }}
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        {{ option.user.user_name }} - {{ option.user.user_email }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('globalSingular.amount')"
                  label-for="amount"
                >
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="amount"
                    rules="required"
                  > -->
                    <!-- <b-form-input
                      id="amount"
                      v-model="form.approvers[index].amount"
                      :state="errors.length > 0 ? false:null"
                      placeholder="1,000,000"
                      :disabled="actions.isPreview"
                    /> -->
                    <cleave
                      id="amount"
                      v-model="form.approvers[index].amount"
                      class="form-control text-right form-control-sm"
                      :options="numeric"
                      :disabled="actions.isPreview"
                      placeholder="1,000,000"
                    />
                    <!-- <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-button v-if="form.approvers.length > 1" size="sm" variant="flat-danger" class="btn-icon" @click="handleDeleteApprover(approver.no)">
                  <feather-icon icon="XIcon" size="16" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              variant="outline-primary"
              class="mr-2"
              block
              @click="addNewApprover"
            >
              Add new Approver
            </b-button>
          </div>

          <div v-if="form.type === 'ALL'">
            <h5 class="font-weight-bolder">Approvers</h5>
            <b-row class="mb-2" v-for="(approver, index) in form.approvers" :key="approver.no">
              <b-col md="2" class="text-center">
                <b-form-group
                  :label="$t('globalSingular.no')"
                >
                  {{ approver.no }}
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('globalSingular.approvers')"
                  label-for="approvers"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="approvers"
                  >
                    <v-select
                      id="approvers"
                      v-model="form.approvers[index].userApprover"
                      :options="LOV.users"
                      :reduce="field => field.user.id"
                      label="remark"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    >
                      <template v-slot:option="option">
                        {{ option.user.user_name }} - {{ option.user.user_email }}
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        {{ option.user.user_name }} - {{ option.user.user_email }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-button v-if="form.approvers.length > 1" size="sm" variant="flat-danger" class="btn-icon" @click="handleDeleteApprover(approver.no)">
                  <feather-icon icon="XIcon" size="16" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              variant="outline-primary"
              class="mr-2"
              block
              @click="addNewApprover"
            >
              Add new Approver
            </b-button>
          </div>

        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-danger"
            @click="showModalForm = false"
            class="mr-2"
          >
            Cancel
          </b-button>
          <b-button
            v-if="!actions.isEditMode"
            variant="primary"
            @click="handleSubmit(null)"
          >
            Create
          </b-button>
          <b-button
            v-else
            variant="primary"
            @click="handleSubmit(selectedApproval.id)"
          >
            Update
          </b-button>
        </div>
      </template>
    </b-modal>
    <div class="m-2">
      <b-row align-v="center">
        <b-col cols="12" md="6" class="mb-1 d-flex align-items-center justify-content-start mb-md-0">
          <div class="d-flex align-items-center">
            <feather-icon
              icon="UserCheckIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              {{ $t('apps.setting.singular.moduleApproval') }}
            </h4>
          </div>
        </b-col>
        <b-col cols="12" md="6" class="text-right">
          <b-button size="md" variant="primary" class="btn-icon" @click="showModal">
            <feather-icon icon="PlusIcon" size="16" />
            {{ $t('apps.setting.actions.addModuleApproval') }}
          </b-button>
        </b-col>
      </b-row>

    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
          <div class="mb-1 text">
            <b-spinner class="align-middle"/>
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: Account Type -->
      <template #cell(menu)="{ item }">
        <!-- {{ data.value.type_name }} -->
        {{ item.transactionType.remark_eng }}
        <!-- <span class="font-weight-bolder d-block text-nowrap">
          {{ (item.contact) ? item.contact.label : 'Transfer' }}
        </span>
        <small class="font-italic">{{ item.remark }} {{ $t('globalSingular.via') }} <span class="font-weight-bolder text-primary ">{{ companyActive.plan_id > 2 ? item.office.label : item.account.account_name }}</span>  </small> -->
      </template>

      <template #cell(actions)="{ item }">
        <b-button size="sm" variant="flat-primary" class="btn-icon" @click="getSelectedApproval(item.id)">
          <feather-icon icon="Edit2Icon" size="16" />
        </b-button>
        <b-button size="sm" variant="flat-danger" class="btn-icon" @click="handleDelete(item.id)">
          <feather-icon icon="XIcon" size="16" />
        </b-button>
      </template>

    </b-table>

  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormCheckboxGroup,
  BRow,
  BModal,
  BCol,
  BCard,
  BButton,
  BTable,
  BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import useFormResource from '@/comp-functions/useFormResource'
import useListTable from '@/comp-functions/useListTable'
import useHttp from '@/comp-functions/useHttp'
import useLocalization from '@/comp-functions/utils/useLocalization'
import Cleave from 'vue-cleave-component'
// import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const approver = {
  no: 1,
  userApprover: null,
  amount: 0
}

export default {
  components: {
    vSelect,
    BForm,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormCheckboxGroup,
    BRow,
    BModal,
    BCol,
    BCard,
    BButton,
    BTable,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    Cleave
  },
  setup () {
    const { toast, $post, $get } = useHttp()
    const { $t } = useLocalization()

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand'
    })

    const lists = useListTable({
      url: 'setting/module-approval'
    })

    const useForm = useFormResource({ url: 'setting/module-approval', localeContextPath: 'apps.setting.singular.moduleApproval' })

    const showModalForm = ref(false)
    const formRef = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'menu', label: $t('globalSingular.menu') },
      { key: 'name', label: $t('globalSingular.name') },
      { key: 'type', label: $t('globalSingular.type') },
      { key: 'actions', label: $t('globalSingular.action') }
    ]

    const LOV = ref({
      transactions: [],
      types: [
        'WORKUNIT',
        'LEVELING',
        'ALL'
      ],
      users: []
    })

    const form = ref({
      name: '',
      type: '',
      transactionTypeCode: '',
      approvers: [approver]
    })

    const selectedApproval = ref(null)

    const addNewApprover = () => {
      form.value.approvers.push({
        no: form.value.approvers.length + 1,
        userApprover: null,
        amount: 0
      })
    }

    const getTransactionLists = async () => {
      const { data } = await $get({ url: 'value/transaction-type' })
      LOV.value.transactions = data
    }
    const getUserCompany = async () => {
      const { data } = await $get({ url: 'value/company-users' })
      LOV.value.users = data
    }

    const handleDeleteApprover = no => {
      form.value.approvers = form.value.approvers.filter(approver => approver.no !== no)
    }

    const showModal = () => {
      showModalForm.value = true
      useForm.actions.value.isEditMode = false
    }

    const getSelectedApproval = id => {
      showModalForm.value = true
      useForm.actions.value.isEditMode = true
      selectedApproval.value = lists.itemLists.value.find(field => field.id === id)
      form.value.name = selectedApproval.value.name
      form.value.transactionTypeCode = selectedApproval.value.transaction_type_code
      form.value.approvers = selectedApproval.value.approvers.map(approver => {
        return {
          no: approver.no,
          userApprover: approver.user_approver,
          amount: parseFloat(approver.amount)
        }
      })
    }

    const resetForm = () => {
      // useForm.actions.value.isEditMode = false
      form.value.name = ''
      form.value.transactionTypeCode = ''
      form.value.approvers = [approver]
    }

    onMounted(() => {
      getTransactionLists()
      getUserCompany()
    })

    return {
      numeric,
      selectedApproval,
      showModalForm,
      tableColumns,
      formRef,
      resetForm,
      showModal,
      required,
      form,
      $post,
      toast,
      LOV,
      addNewApprover,
      handleDeleteApprover,
      getSelectedApproval,
      ...lists,
      ...useForm
    }
  },
  methods: {
    async handleSubmit (id) {
      const callbackSuccess = () => {
        this.fetchLists()
        this.showModalForm = false
      }

      const url = id ? `setting/module-approval/${id}` : null
      const form = {...this.form}
      this.store({
        $swal: this.$swal,
        data: form,
        url
      }, callbackSuccess)
    },
    handleEdit () {
    },
    async handleDelete (id) {
      await this.destroy(this.$swal, `setting/module-approval/${id}`, () => {
        this.itemLists = this.itemLists.filter(field => field.id !== id)
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
