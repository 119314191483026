<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="formRef">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <div class="mb-2 d-flex align-items-center">
              <feather-icon
                icon="SendIcon"
                size="18"
              />
              <h4 class="mb-0 ml-75">
                Invite User
              </h4>
            </div>
          </b-col>

          <!-- Invited Name -->
          <b-col md="4">
            <b-form-group
              :label="$t('apps.setting.singular.name')"
              label-for="invited-name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="invited-name"
                  size="sm"
                  v-model="form.invitedName"
                  name="invited-name"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Invited Name -->
          <b-col md="4">
            <b-form-group
              :label="$t('apps.setting.singular.userCode')"
              label-for="invited-user-code"
            >
              <validation-provider
                #default="{ errors }"
                name="userCode"
                rules="required"
              >
                <b-form-input
                  id="invited-user-code"
                  size="sm"
                  v-model="form.invitedUserCode"
                  name="invited-user-code"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Invited Name -->

          <!-- Invited Name -->
          <b-col md="4">
            <b-form-group
              :label="$t('apps.setting.singular.email')"
              label-for="invited-email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-form-input
                  id="invited-email"
                  size="sm"
                  v-model="form.invitedEmail"
                  name="invited-email"
                  type="email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Invited Name -->

          <!-- Invited Name -->
          <b-col md="3">
            <b-form-group
              :label="$t('apps.setting.singular.roles')"
              label-for="roles"
            >
              <validation-provider
                #default="{ errors }"
                name="roles"
                rules="required"
              >
                <v-select
                  class="select-size-sm"
                  inputId="roles"
                  v-model="form.roleId"
                  :options="LOV.roles"
                  :reduce="field => field.id"
                  label="name"
                >
                  <template #option="{ name }">
                    {{ name }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Invited Name -->

          <!-- Invited Name -->
          <b-col md="3">
            <b-form-group
              :label="$t('globalSingular.office')"
              label-for="office"
            >
              <validation-provider
                #default="{ errors }"
                name="office"
                rules="required"
              >
                <v-select
                  class="select-size-sm"
                  inputId="roles"
                  v-model="form.officeId"
                  :options="LOV.offices"
                  :reduce="field => field.id"
                  label="name"
                >
                  <template #option="{ name }">
                    {{ name }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Invited Name -->

          <!-- Is National -->
          <b-col md="3">
            <b-form-group :label="$t('globalSingular.national')">
              <b-form-radio-group
                v-model="form.isNational"
                :options="radioButton"
                name="radios-stacked"
              />
            </b-form-group>
          </b-col>
          <!--/ Is National -->

          <!-- Add button -->
          <b-col md="2">
            <b-button block variant="primary" class="mt-2 pd-1" @click="handleSubmit">
              {{ $t('apps.setting.actions.invite') }}
            </b-button>
          </b-col>
          <!--/ Add button -->

        </b-row>
      </b-form>
    </validation-observer>

    <hr>

    <b-row>
      <b-col cols="12">
        <div class="mb-2 d-flex align-items-center">
          <feather-icon
            icon="ListIcon"
            size="18"
          />
          <h4 class="mb-0 ml-75">
            User List
          </h4>
        </div>
      </b-col>
    </b-row>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
          <div class="mb-1 text">
            <b-spinner class="align-middle"/>
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #cell(code)="data">
          <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.user_name }}
        </span>
      </template>

      <template #cell(date)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.user_email }}
        </span>
      </template>

      <template #cell(purpose_date)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.status }}
        </span>
      </template>

      <template #cell(actions)="data">
        <b-button size="sm" variant="outline-primary" v-if="data.item.status === 'Invited'" @click="resendEmail(data.item.company_id, data.item.user_email)">
          Resend Email
        </b-button>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mt-1 mb-0 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BPagination,
  BSpinner
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import useListTable from '@/comp-functions/useListTable'
import useHttp from '@/comp-functions/useHttp'
import store from '@/store/index'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    vSelect
  },
  setup () {
    const { toast, $post, $get } = useHttp()

    const formRef = ref(null)

    const LOV = ref({
      roles: [],
      offices: []
    })

    // Table Handlers
    const tableColumns = [
      { key: 'user_name', label: 'Name', sortable: true },
      { key: 'user_code', label: 'User Code' },
      { key: 'user_email', label: 'Email' },
      { key: 'role', label: 'Roles' },
      { key: 'status', label: 'Status' },
      { key: 'actions', label: 'Action' }
    ]

    const columnToBeFilter = [
      'user_name',
      'user_email',
      'status'
    ]

    const form = ref({
      invitedName: '',
      invitedUserCode: '',
      invitedEmail: '',
      roleId: null,
      officeId: null,
      companyId: store.state.user.currentCompany.id,
      isNational: false
    })

    const getRoles = async () => {
      const { data } = await $get({ url: 'setting/roles' })
      LOV.value.roles = data
    }

    const getOffices = async () => {
      const { data } = await $get({ url: 'master/offices' })
      LOV.value.offices = data
    }

    const clearForm = () => {
      console.log('formRef.value => ', formRef.value)
      formRef.value.reset()
      form.value.invitedName =  null
      form.value.invitedEmail =  null
      form.value.roleId =  null
      form.value.officeId =  null
      form.value.companyId =  null
      form.value.isNational =  false
    }

    onMounted(() => {
      getOffices()
      getRoles()
    })

    return {
      clearForm,
      LOV,
      tableColumns,
      columnToBeFilter,
      formRef,
      required,
      form,
      $post,
      toast,
      ...useListTable({
        url: `company/${form.value.companyId}/users`
      }),
      radioButton: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ]
    }
  },
  methods: {
    async handleSubmit () {
      const $swal = this.$swal

      const validated = await this.formRef.validate()
      if (validated) {
        $swal({
          title: 'Invite this user ?',
          text: `After invited, ${this.$t('info.notAbleRecover')}.`,
          icon: 'question',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-flat-warning'
          }
        }).then(async ({value: password}) => {
          if (password) {
            const callbackSuccess = () => {
              this.clearForm()
              this.fetchLists()
              this.toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${this.$t('feedback.success')} invited user.`
                }
              })
            }

            this.$post({
              data: {...this.form},
              url: 'company/user-invitation'
            }).then(callbackSuccess)
          }
        })
      }
    },
    resendEmail (company_id, user_email) {
      const callbackSuccess = () => {
        this.fetchLists()
        this.toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'BellIcon',
            variant: 'success',
            text: 'Invitation email sent!'
          }
        })
      }

      this.$post({
        data: { company_id, invited_email: user_email },
        url: 'company/user-invitation/resend-email'
      }).then(callbackSuccess)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
