<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="formRef">
      <b-form @submit.prevent>
        <b-row>
          <!-- api key -->
          <b-col md="10">
            <b-form-group
              :label="$t('apps.setting.singular.integrationToken')"
              label-for="integration-key"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="integration-key"
                  v-model="form.integrationToken"
                  name="integration-key"
                  :type="integrationTokenField"
                  readonly
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="apiKeyToggleIcon"
                    class="cursor-pointer"
                    @click="toggleApiKey"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ api key -->

          <!-- generate button -->
          <b-col md="2">
            <b-button block :variant="form.integrationToken ? 'danger' : 'primary'" class="mt-2 pd-1" @click="handleSubmit">
              {{ form.integrationToken ? $t('globalActions.delete') : $t('globalActions.generate') }}
            </b-button>
          </b-col>
          <!--/ generate button -->

        </b-row>

        <b-row class="mt-2">
          <b-col md="12">
            <b-alert variant="warning" show>
              <div class="alert-body">
            <span>
              <b>Always keep your integration token secret!</b>
              You can use this integration token to integrate your system.<br>
              If you feel that your integration token is unsafe or someone outside your company members knows this key, you can reset and generate it again.
            </span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BAlert
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
// import useFormResource from '@/comp-functions/useFormResource'
import useHttp from '@/comp-functions/useHttp'
import store from '@/store/index'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BAlert,
    ValidationObserver
  },
  setup () {
    const { toast, $put } = useHttp()

    const form = ref({
      integrationToken: '',
      user_email: '',
      user_name: 'integration'
    })

    const integrationTokenField = 'password'

    onMounted(() => {
      const domainEmail = `${store.state.user.currentCompany.brand.toLowerCase().replace(/\s+/g, '')}.com` ??
        store.state.user.currentCompany.website ??
        `${store.state.user.currentCompany.name.toLowerCase().replace(/\s+/g, '')}.com`

      form.value.integrationToken = store.state.user.currentCompany.integration_token
      form.value.user_email = `integration@${domainEmail}`
    })

    return {
      form,
      $put,
      toast,
      integrationTokenField
    }
  },
  computed: {
    apiKeyToggleIcon () {
      return this.integrationTokenField === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    toggleApiKey () {
      this.integrationTokenField = this.integrationTokenField === 'password' ? 'text' : 'password'
    },
    handleSubmit () {
      if (this.form.integrationToken) {
        this.deleteHandler()
      } else {
        this.saveHandler()
      }
    },
    saveHandler () {
      const $swal = this.$swal

      $swal({
        title: `${this.$t('confirm.createNew')} ${this.$t('apps.setting.singular.integrationToken')} ?`,
        text: `${this.$t('info.afterCreated')} ${this.$t('info.stillCanDelete')}.`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async ({value: password}) => {
        if (password) {
          const callbackSuccess = (res) => {
            this.form.integrationToken = res.data.company.integration_token
            store.commit('user/SET_CURRENT_COMPANY', res.data.company)

            this.toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: `${this.$t('feedback.success')} ${this.$t('feedback.createdNew')} ${this.$t('apps.setting.singular.integrationToken')}.`
              }
            })
          }

          this.$put({
            data: { ...this.form },
            url: `company/${store.state.user.currentCompany.id}/generate-integration-token`
          }).then((res) => callbackSuccess(res))
        }
      })
    },
    deleteHandler () {
      const $swal = this.$swal

      $swal({
        title: 'Enter your password',
        input: 'password',
        inputLabel: 'Password',
        inputPlaceholder: 'Password',
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off'
        },
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async ({value: password}) => {
        if (password) {
          const callbackSuccess = (res) => {
            this.form.integrationToken = res.data.company.integration_token
            store.commit('user/SET_CURRENT_COMPANY', res.data.company)

            this.toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: `${this.$t('feedback.success')} ${this.$t('feedback.successDeleted')} ${this.$t('apps.setting.singular.integrationToken')}.`
              }
            })
          }

          this.$put({
            data: { ...this.form, password },
            url: `company/${store.state.user.currentCompany.id}/delete-integration-token`
          }).then((res) => callbackSuccess(res))
        }
      })
    }
  }
}
</script>
