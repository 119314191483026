<template>
  <b-card>
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar
            rounded
            ref="refPreviewLogo"
            v-if="form.logoUrl"
            :src="form.logoUrl"
            size="5rem"
          />

          <b-avatar
            rounded
            v-else
            :text="form.name.charAt(0)"
            size="5rem"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refLogo.$el.click()"
        >
          Upload
        </b-button>
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="removeUploaded"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>

    <!-- form -->
    <validation-observer ref="formRef">
      <b-form @submit.prevent>

        <b-form-file
          ref="refLogo"
          v-model="form.logo"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="handleUpload"
        />

        <b-row class="mt-2">
          <b-col md="12">
            <b-alert variant="info" show>
              <div class="alert-body">
                <feather-icon icon="InfoIcon" />
                <span class="ml-1">Company data will be used like entry invoicing, print invoice, etc</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <b-row>
          <!-- company name -->
          <b-col md="8">
            <b-form-group
              :label="$t('apps.setting.singular.companyName')"
              label-for="name"
            >
              <b-form-input
                id="name"
                size="sm"
                v-model="form.name"
                name="name"
              />
            </b-form-group>
          </b-col>
          <!--/ company name -->

        </b-row>

        <b-row>
          <!-- company brand -->
          <b-col md="8">
            <b-form-group
              :label="$t('apps.setting.singular.companyBrand')"
              label-for="brand"
            >
              <b-form-input
                id="brand"
                size="sm"
                v-model="form.brand"
                name="brand"
              />
            </b-form-group>
          </b-col>
          <!--/ company brand -->

        </b-row>

        <b-row>
          <!-- business type -->
          <b-col md="8">
            <b-form-group
              :label="$t('apps.setting.singular.businessType')"
              label-for="business-type"
            >
              <v-select
                v-model="form.businessType"
                id="business-type"
                label="name"
                class="select-size-sm"
                :options="LOV.businessTypes"
                :reduce="field => field.value"
              />
            </b-form-group>
          </b-col>
          <!--/ company brand -->

        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0">

            <b-button
              variant="primary"
              type="submit"
              @click="handleSubmit"
            >
              <feather-icon
                icon="SaveIcon"
              />
              {{ $t('globalActions.update') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BRow,
  BCol,
  BCard,
  BButton,
  BAlert,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BAvatar,
  BCardText
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import useHttp from '@/comp-functions/useHttp'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BCardText,
    ValidationObserver,
    vSelect
  },
  setup () {
    const companyActive = store.state.user.currentCompany

    const form = ref({
      id: companyActive.id,
      name: companyActive.name,
      brand: companyActive.brand,
      businessType: companyActive.business_type,
      logoUrl: companyActive.logo,
      logo: null
    })

    const LOV = ref({
      businessTypes: [
        { value: 1, name: 'Service' },
        { value: 2, name: 'Trading' },
        { value: 3, name: 'Manufacture' }
      ]
    })

    const { toast, $put } = useHttp()

    return {
      form,
      $put,
      toast,
      LOV,
      companyActive
    }
  },
  methods: {
    handleUpload () {
      const reader = new FileReader()
      if (this.form.logo) {
        reader.readAsDataURL(this.form.logo)
        reader.onload = () => {
          this.form.logoUrl = reader.result
        }
      }
    },
    removeUploaded () {
      this.form.logo = null
      this.form.logoUrl = null
    },
    handleSubmit () {
      const $swal = this.$swal

      $swal({
        title: `${this.$t('confirm.updateThis')} ${this.$t('apps.setting.singular.company')} ?`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async () => {
        const form = new FormData()
        form.append('logo', this.form.logo)
        form.append('name', this.form.name)
        form.append('brand', this.form.brand)
        form.append('business_type', this.form.businessType)

        const callbackSuccess = (res) => {
          res.data.business_type = parseInt(res.data.business_type)
          store.commit('user/SET_CURRENT_COMPANY', res.data)

          this.toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'BellIcon',
              variant: 'success',
              text: `${this.$t('feedback.success')} ${this.$t('feedback.updated')} ${this.$t('apps.setting.singular.company')}.`
            }
          })
        }

        this.$put({
          data: form,
          url: `company/${this.form.id}`
        }).then((res) => callbackSuccess(res))
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
