var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2 d-flex align-items-center"},[_c('feather-icon',{attrs:{"icon":"SlidersIcon","size":"18"}}),_c('h4',{staticClass:"mb-0 ml-75"},[_vm._v(" "+_vm._s(_vm.$t('globalSingular.limitAdvance'))+" ")])],1)]),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.setting.singular.limitType'),"label-for":"limitTypes"}},[_c('v-select',{staticClass:"select-size-sm",attrs:{"inputId":"limitTypes","options":_vm.LOV.limitTypes,"reduce":function (field) { return field.code; },"label":"name"},on:{"option:selected":_vm.handleChangeLimitType},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.form.limitType),callback:function ($$v) {_vm.$set(_vm.form, "limitType", $$v)},expression:"form.limitType"}})],1)],1)],1)],1)],1),(_vm.showForm)?_c('hr'):_vm._e(),(_vm.showForm)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2 d-flex align-items-center"},[_c('feather-icon',{attrs:{"icon":"ListIcon","size":"18"}}),_c('h4',{staticClass:"mb-0 ml-75"},[_vm._v(" Limit List ")])],1)])],1):_vm._e(),(_vm.showForm)?_c('b-table',{ref:"refListTable",staticClass:"position-relative",attrs:{"id":"refListTable","no-provider-paging":"","no-provider-filtering":"","fields":_vm.tableColumns,"items":_vm.form.details,"responsive":"","primary-key":"id","show-empty":"","busy":_vm.tableBusy},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"my-2 text-center text-primary d-flex flex-column justify-content-center"},[_c('div',{staticClass:"mb-1 text"},[_c('b-spinner',{staticClass:"align-middle"})],1),_c('strong',[_vm._v(_vm._s(_vm.$t('globalActions.fetchingDataAPI')))])])]},proxy:true},{key:"cell(limit_type)",fn:function(data){return [_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"cell(refund_first)",fn:function(ref){
var index = ref.index;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","inline":""},on:{"change":function (value) { return _vm.handleSwitchForm({ index: index, name: 'isRefundFirst' }); }},model:{value:(_vm.form.details[index].isRefundFirst),callback:function ($$v) {_vm.$set(_vm.form.details[index], "isRefundFirst", $$v)},expression:"form.details[index].isRefundFirst"}})]}},{key:"cell(realization_first)",fn:function(ref){
var index = ref.index;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","inline":""},on:{"change":function (value) { return _vm.handleSwitchForm({ index: index, name: 'isRealizationFirst' }); }},model:{value:(_vm.form.details[index].isRealizationFirst),callback:function ($$v) {_vm.$set(_vm.form.details[index], "isRealizationFirst", $$v)},expression:"form.details[index].isRealizationFirst"}})]}},{key:"cell(must_zero)",fn:function(ref){
var index = ref.index;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","inline":""},on:{"change":function (value) { return _vm.handleSwitchForm({ index: index, name: 'balanceMustZero' }); }},model:{value:(_vm.form.details[index].balanceMustZero),callback:function ($$v) {_vm.$set(_vm.form.details[index], "balanceMustZero", $$v)},expression:"form.details[index].balanceMustZero"}})]}},{key:"cell(amount)",fn:function(ref){
var index = ref.index;
return [_c('cleave',{staticClass:"w-auto text-right form-control form-control-sm",attrs:{"options":_vm.numeric,"disabled":_vm.form.details[index].isRealizationFirst || _vm.form.details[index].isRefundFirst || _vm.form.details[index].balanceMustZero,"placeholder":"1,000,000"},model:{value:(_vm.form.details[index].amount),callback:function ($$v) {_vm.$set(_vm.form.details[index], "amount", _vm._n($$v))},expression:"form.details[index].amount"}})]}}],null,false,3995366737)}):_vm._e(),_c('div',{staticClass:"pb-3 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" Save ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }