<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="formRef">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <div class="mb-2 d-flex align-items-center">
              <feather-icon
                icon="SlidersIcon"
                size="18"
              />
              <h4 class="mb-0 ml-75">
                {{ $t('globalSingular.limitAdvance') }}
              </h4>
            </div>
          </b-col>
          <!-- Limit By -->
          <b-col md="3">
            <b-form-group
              :label="$t('apps.setting.singular.limitType')"
              label-for="limitTypes"
            >
              <!-- <validation-provider
                #default="{ errors }"
                name="limitTypes"
                rules="required"
              > -->
                <v-select
                  class="select-size-sm"
                  inputId="limitTypes"
                  v-model="form.limitType"
                  :options="LOV.limitTypes"
                  :reduce="field => field.code"
                  label="name"
                  @option:selected="handleChangeLimitType"
                >
                  <template #option="{ name }">
                    {{ name }}
                  </template>
                </v-select>
                <!-- <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <!--/ Limit By -->

          <!-- Add button -->
          <!-- <b-col md="2">
            <b-button block variant="primary" class="mt-2 pd-1" @click="handleSubmit">
              {{ $t('apps.setting.actions.invite') }}
            </b-button>
          </b-col> -->
          <!--/ Add button -->

        </b-row>
      </b-form>
    </validation-observer>

    <hr v-if="showForm">

    <b-row v-if="showForm">
      <b-col cols="12">
        <div class="mb-2 d-flex align-items-center">
          <feather-icon
            icon="ListIcon"
            size="18"
          />
          <h4 class="mb-0 ml-75">
            Limit List
          </h4>
        </div>
      </b-col>
    </b-row>

    <b-table
      v-if="showForm"
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="form.details"
      responsive
      primary-key="id"
      show-empty
      :busy="tableBusy"
      class="position-relative"
    >

      <template #table-busy>
        <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
          <div class="mb-1 text">
            <b-spinner class="align-middle"/>
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #cell(limit_type)="data">
          <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.name }}
        </span>
      </template>

      <template #cell(refund_first)="{ index }">
        <!-- <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.user_email }}
        </span> -->
        <!-- <b-input-group-append is-text> -->
        <!-- <b-form-radio v-model="form.details[index].isRefundFirst" value="true">
        </b-form-radio> -->
        <b-form-checkbox
          v-model="form.details[index].isRefundFirst"
          name="check-button"
          inline
          @change="value => handleSwitchForm({ index, name: 'isRefundFirst' })"
        >
        </b-form-checkbox>
          <!-- </b-input-group-append> -->
      </template>

      <template #cell(realization_first)="{ index }">
        <b-form-checkbox
          v-model="form.details[index].isRealizationFirst"
          name="check-button"
          inline
          @change="value => handleSwitchForm({ index, name: 'isRealizationFirst' })"
        >
        </b-form-checkbox>
      </template>

      <template #cell(must_zero)="{ index }">
        <b-form-checkbox
          v-model="form.details[index].balanceMustZero"
          name="check-button"
          inline
          @change="value => handleSwitchForm({ index, name: 'balanceMustZero' })"
        >
        </b-form-checkbox>
      </template>

      <template #cell(amount)="{ index }">
        <cleave
          v-model.number="form.details[index].amount"
          :options="numeric"
          :disabled="form.details[index].isRealizationFirst || form.details[index].isRefundFirst || form.details[index].balanceMustZero"
          class="w-auto text-right form-control form-control-sm"
          placeholder="1,000,000"
        />
      </template>
    </b-table>

    <div class="pb-3 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-end">
      <b-button
        variant="primary"
        @click="handleSubmit"
      >
        <feather-icon
          icon="EditIcon"
        />
        Save
        </b-button>
    </div>

  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  // BFormInput,
  // BFormRadio,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  // BPagination,
  BSpinner
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { onMounted, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import useListTable from '@/comp-functions/useListTable'
import useHttp from '@/comp-functions/useHttp'
// import store from '@/store/index'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    // BFormInput,
    // BFormRadio,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    // BPagination,
    BSpinner,
    ValidationObserver,
    // ValidationProvider,
    vSelect,
    Cleave
  },
  setup () {
    const { toast, $post, $get } = useHttp()

    const formRef = ref(null)
    const tableBusy = ref(false)
    const showForm = ref(false)
    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand'
    })

    const LOV = ref({
      limitTypes: []
    })

    // Table Handlers
    const tableColumns = [
      { key: 'limit_type', label: 'Name' },
      { key: 'refund_first', label: 'Refund First' },
      { key: 'realization_first', label: 'Realization First' },
      { key: 'must_zero', label: 'Must Zero Outstanding' },
      { key: 'amount', label: 'amount' }
    ]

    const form = ref({
      limitType: '',
      details: []
    })

    const getLimitType = async () => {
      const { data } = await $get({ url: 'value/system-codes?module=CASH_ADVANCE_CONFIG' })
      LOV.value.limitTypes = data
    }

    const clearForm = () => {
      console.log('formRef.value => ', formRef.value)
      formRef.value.reset()
      form.value.invitedName =  null
      form.value.invitedEmail =  null
      form.value.roleId =  null
      form.value.officeId =  null
      form.value.companyId =  null
      form.value.isNational =  false
    }

    const handleChangeLimitType = async limit => {
      let url = ''
      showForm.value = true
      form.value.details = []
      tableBusy.value = true
      url = limit.code === 'TYPE' ? 'value/system-codes?module=CASH_ADVANCE_PURPOSE' : 'value/work-unit'
      
      if (limit.code === 'ALL') {
        form.value.details = [
          {
            code: 'ALL',
            name: 'ALL',
            limitType: limit.code,
            isRefundFirst: false,
            isRealizationFirst: false,
            balanceMustZero: false,
            amount: 0
          }
        ]
      } else {
        const { data } = await $get({ url })
        form.value.details = data.map(field => {
          let name = ''
          if (limit.code === 'WORKUNIT') {
            name = `${field.type} - ${field.name}`
          } else if (limit.code === 'TYPE') {
            name = field.name
          } else {
            name = 'ALL'
          }
          return {
            code: field.code,
            name,
            limitType: limit.code,
            isRefundFirst: false,
            isRealizationFirst: false,
            balanceMustZero: false,
            amount: 0
          }
        })
      }
      tableBusy.value = false
    }

    const handleSwitchForm = ({name, index}) => {
      if (name === 'isRefundFirst') {
        form.value.details[index].isRealizationFirst = false
        form.value.details[index].balanceMustZero = false
        form.value.details[index].amount = 0
      } else if (name === 'isRealizationFirst') {
        form.value.details[index].isRefundFirst = false
        form.value.details[index].balanceMustZero = false
        form.value.details[index].amount = 0
      } else {
        form.value.details[index].isRealizationFirst = false
        form.value.details[index].isRefundFirst = false
        form.value.details[index].amount = 0
      }
    }

    const getActiveLimitType = async () => {
      const { data } = await $get({ url: 'setting/cash-advance' })
      if (data) {
        tableBusy.value = true
        form.value.limitType = data.limit_type
        form.value.details = data.details.map(field => {
          let name = ''
          if (data.limit_type === 'WORKUNIT') {
            name = `${field.workUnits.type} - ${field.workUnits.name}`
          } else if (data.limit_type === 'TYPE') {
            name = field.codes.name
          } else {
            name = 'ALL'
          }
          return {
            code: field.code,
            name,
            limitType: field.limit_type,
            isRefundFirst: field.is_refund_first,
            isRealizationFirst: field.is_realization_first,
            balanceMustZero: field.balance_must_zero,
            amount: 0
          }
        })
        showForm.value = true
        tableBusy.value = false
      }
    }

    onMounted(() => {
      getActiveLimitType()
      getLimitType()
    })

    return {
      numeric,
      showForm,
      tableBusy,
      handleChangeLimitType,
      clearForm,
      LOV,
      tableColumns,
      handleSwitchForm,
      // columnToBeFilter,
      formRef,
      required,
      form,
      $post,
      toast,
      // ...useListTable({
      //   url: `setting/${form.value.companyId}/users`
      // }),
      radioButton: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ]
    }
  },
  methods: {
    async handleSubmit () {
      const $swal = this.$swal

      const validated = await this.formRef.validate()
      if (validated) {
        $swal({
          title: 'Change Advance limit type ?',
          text: 'This will change the rules when user requesting advance',
          icon: 'question',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-flat-warning'
          }
        }).then(async ({value}) => {
          if (value) {
            const callbackSuccess = () => {
              this.clearForm()
              // this.fetchLists()
              this.toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${this.$t('feedback.success')} changing limit.`
                }
              })
            }

            this.$post({
              data: {...this.form},
              url: 'setting/cash-advance'
            }).then(callbackSuccess)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
