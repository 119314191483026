<template>
  <b-tabs
    vertical
    content-class="mt-1 col-12 col-md-9 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    lazy
  >
    <!-- user company -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShieldIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('apps.setting.singular.company') }}</span>
      </template>

      <company />
    </b-tab>
    <!--/ user company -->

    <!-- user invitation tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('apps.setting.singular.userInvitation') }}</span>
      </template>

      <user-invitation />
    </b-tab>
    <!--/ user invitation tab -->

    <!-- Limit Advance tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('globalSingular.limitAdvance') }}</span>
      </template>

      <cash-advance-limit />
    </b-tab>
    <!--/ Limit Advance tab -->

<!-- company roles -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="MenuIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('apps.setting.singular.roles') }}</span>
      </template>

      <roles />
    </b-tab>
    <!--/ company roles -->

    <!-- module approval tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserCheckIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('apps.setting.singular.moduleApproval') }}</span>
      </template>

      <module-approval />
    </b-tab>
    <!-- / module approval tab -->

    <!-- integration token tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="KeyIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('apps.setting.singular.integrationToken') }}</span>
      </template>

      <integration-token />
    </b-tab>
    <!--/ integration token tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Roles from './roles'
import ModuleApproval from './module-approvals'
import Company from './Company.vue'
import UserInvitation from './user-invitation/UserInvitation.vue'
import IntegrationToken from './IntegrationToken.vue'
import CashAdvanceLimit from './CashAdvanceLimit.vue'

export default {
  components: {
    BTabs,
    BTab,
    Roles,
    ModuleApproval,
    Company,
    UserInvitation,
    IntegrationToken,
    CashAdvanceLimit
  },
  data () {
    return {
      options: {}
    }
  },
  beforeCreate () {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  }
}
</script>
